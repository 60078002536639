<template>
    <div class="panel panel-default">
        <!-- TODO need timepickers on all these Datepickers -->
        <div class="panel-heading"></div>
        <div class="row panel-body">
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Start Date From</label><DatePicker :pickTime="true" :format="'MM/DD/YYYY hh:mm'" :input-attributes="{ class: 'form-control'}" v-model="state.optionFields.fromDate.val" />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Start Date To</label><DatePicker :pickTime="true" :format="'MM/DD/YYYY hh:mm'" :input-attributes="{ class: 'form-control'}" v-model="state.optionFields.toDate.val" />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>End Date From</label><DatePicker :pickTime="true" :format="'MM/DD/YYYY hh:mm'" :input-attributes="{ class: 'form-control'}" v-model="state.optionFields.endFromDate.val" />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>End Date To</label><DatePicker :pickTime="true" :format="'MM/DD/YYYY hh:mm'" :input-attributes="{ class: 'form-control'}" v-model="state.optionFields.endToDate.val" />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Created By</label>
                <Typeahead cdi_type="employee" v-bind:ID.sync="state.optionFields.createdBy.val" />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Assigned To</label>
                <Typeahead cdi_type="employee" v-bind:ID.sync="state.optionFields.assignedTo.val" />
            </div>
            <div class='col-md-3 col-sm-4 col-xs-6 form-group'>
                <label>Type</label>
                <select class="form-control" v-model="state.optionFields.type.val">
                    <option value="0">All</option>
                    <option value="1">Email</option>
                    <option value="2">Call</option>
                    <option value="3">Meeting</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Person</label>
                <Typeahead cdi_type="user" v-bind:ID.sync="state.optionFields.distributor.val" />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid" v-bind:class="{ toggle_hide: toggleHide }">
                <label>Date Created From</label><DatePicker :pickTime="true" :format="'MM/DD/YYYY hh:mm'" :input-attributes="{ class: 'form-control'}" v-model="state.optionFields.createdFromDate.val" />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid" v-bind:class="{ toggle_hide: toggleHide }">
                <label>Date Created To</label><DatePicker :pickTime="true" :format="'MM/DD/YYYY hh:mm'" :input-attributes="{ class: 'form-control'}" v-model="state.optionFields.createdToDate.val" />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid" v-bind:class="{ toggle_hide: toggleHide }">
                <label>Date Completed From</label><DatePicker :pickTime="true" :format="'MM/DD/YYYY hh:mm'" :input-attributes="{ class: 'form-control'}" v-model="state.optionFields.completedFromDate.val" />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid" v-bind:class="{ toggle_hide: toggleHide }">
                <label>Date Completed To</label><DatePicker :pickTime="true" :format="'MM/DD/YYYY hh:mm'" :input-attributes="{ class: 'form-control'}" v-model="state.optionFields.completedToDate.val" />
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form_grid" v-bind:class="{ toggle_hide: toggleHide }">
                <label><input type="checkbox" :true-value="1" :false-value=null v-model="state.optionFields.completedOnly.val"> Completed Only</label>
            </div>
            <div class="col-xs-12">
                <button type="button" @click="toggleMoreFilters" class="btn btn-default btn-sm">
                    {{ toggleHide ? 'More' : 'Less' }} Filters
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import { store } from '@/store/Report.store.js';
    import cdiLocationCache from '@/cache/cdiLocation.cache.js';
    import Typeahead from "@/components/utils/typeaheads/Typeahead";
    import DatePicker from "@/components/utils/DatePicker";

    export default {
        name: "Options",
        components: {
            DatePicker,
            Typeahead
        },
        data() {
            return {
                state: store.state,
                toggleHide: true,
                cache: {
                    cdiLocationCache
                }
            }
        },
        methods: {
            toggleMoreFilters: function () {
                this.toggleHide = !this.toggleHide;
            }
        },
        watch:{
            toggleHide: function () {
                this.state.toggleHide = this.toggleHide;
            }
        }
    }
</script>

<style scoped>

</style>