<template>
    <div>
        <h1>Communications</h1>
        <form @submit.prevent="runReport">
            <Options />
            <Fields />
            <Results />
        </form>
    </div>
</template>

<script>
    import { store } from "@/store/Report.store";
    import DateHelpers from "@/store/DateHelpers";
    import Options from "@/components/reports/crm_rpt_opt";
    import Results from "@/components/reports/utils/Results";
    import Fields from "@/components/reports/utils/Fields";

    export default {
        name: "crm_rpt",
        components: {
            Fields,
            Results,
            Options
        },
        data() {
            return {
                state: store.state,
                optionFields: {
                    fromDate: {val: `${DateHelpers.getFirstOfTheMonth()} 00:00`, qsField: '[dateandtimes][start_dt_from]'},
                    toDate: {val: '', qsField: '[dateandtimes][start_dt_to]'},
                    endFromDate: {val: '', qsField: '[dateandtimes][end_dt_from]'},
                    endToDate: {val: '', qsField: '[dateandtimes][end_dt_to]'},
                    createdBy: {val: '', qsField: '[numbers][created_by]'},
                    assignedTo: {val: '', qsField: '[numbers][assigned_to]'},
                    type: {val: 0, qsField: '[numbers][c.type]'},
                    distributor: {val: '', qsField: '[numbers][distributor.user_id]'},
                    createdFromDate: {val: '', qsField: '[dateandtimes][created_dt_from]', defaultVal: ''},
                    createdToDate: {val: '', qsField: '[dateandtimes][created_dt_to]', defaultVal: ''},
                    completedFromDate: {val: '', qsField: '[dateandtimes][completed_dt_from]', defaultVal: ''},
                    completedToDate: {val: '', qsField: '[dateandtimes][completed_dt_to]', defaultVal: ''},
                    completedOnly: {val: null, qsField: '[isset][completed]', defaultVal: null}
                },
                groupByFields: {},
                dataFields: {
                    start_dt: { label: 'Start', checked: true},
                    end_dt: { label: 'End', checked: true },
                    company: { label: 'Company', checked: true },
                    branch: { label: 'Branch', checked: true },
                    distributor: { label: 'Person', checked: true },
                    type: { label: 'Type', checked: true },
                    note: { label: 'Note' },
                    created_by: { label: 'Created By' },
                    created_dt: { label: 'Created Date' },
                    assigned_to: { label: 'Assigned To', checked: true },
                    completed_dt: { label: 'Completed Date' },
                    completed: { label: 'Completed' },
                }
            }
        },
        methods: {
            runReport: function() {
                store.runReport();
            }
        },
        created() {
            this.$appStore.setTitle(['Communications']);
            this.$set(store.state.results, this.$options.name, '');
            store.setReport(this.$options.name, this.optionFields, this.groupByFields, this.dataFields);
        }
    }
</script>

<style scoped>

</style>